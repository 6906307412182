import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import LaunchButton from '../../atoms/LaunchButton'
import HomeContainer from '../../atoms/HomeContainer'
import PictoPiggyLarge from '../../../assets/piggy-large.inline.svg'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPiggyLarge,
  title: 'En moyenne, il est possible de louer votre bien jusqu\'à 40% plus cher que le prix du marché avec le statut LMNP',
}]

const Container = styled.div`
  background-color: ${({ theme }) => get(theme, 'paleGrey')}; 
  padding-top: 72px;
  padding-bottom: 72px;
`

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin: 24px auto 40px;
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin-top: 16px;
`
const LMNPAutres = () => (
  <Container>
    <HomeContainer>
      <HomeSectionTitle tag='h3'>
        Autres avantages de la location meublée non professionnelle
      </HomeSectionTitle>
      <HomeSectionSubtitle>
      Outre les déductions fiscales, d'autres bénéfices sont liés à la location LMNP.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Par exemple, le fait de louer un appartement totalement meublé et entièrement équipé permet de proposer un loyer plus cher que le prix du marché.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        D'autres part, le bail de location meublée court sur un an, contre trois années pour les locations nues. Il est conseillé de privilégier les petites surfaces dans les zones à flux tendu pour profiter véritablement des avantages dinanciers : les loyers seront encore plus élevés en zone à flux tendu.
      </HomeSectionDescription>
      <BottomText>
        <strong>Vous cherchez un bien immobilier pour faire de la location LMNP ?</strong><br/> Faites-vous accompagner par Folhomee.
      </BottomText>
      <StyledButtonContainer>
        <LaunchButton
          title='Tous les biens immobiliers à vendre sont chez Folhomee'
          background='blue' />
      </StyledButtonContainer>
      <HomeSectionDescription>
        Nous vous proposons une recherche affinée : jusqu'à 25 critères de recherches cumulables.
      </HomeSectionDescription>
      <StyledHomeSectionDescription>
        <strong>Assurez-vous de trouver le bien immobilier que vous cherchez pour votre projet de location LMNP et recevez les alertes des annonces immobilières qui vous correspondent vraiment, en temps réel.</strong>
      </StyledHomeSectionDescription>
    </HomeContainer>
  </Container>
)

export default LMNPAutres
