import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import LMNPImage from '../../molecules/InvestissementLMNPImage'
import TopSection from '../../molecules/TopSection'
import InvestissementLMNP from '../../molecules/InvestissementLMNP'

const StyledLMNPImage = styled(LMNPImage)`
  z-index: -1;
  display: none;
  ${media.greaterThan('xl')`
    display: block;
    height: 624px; 
    top: 220px;
  `}
`

const LMNPTopSection = () => (
  <TopSection component={StyledLMNPImage}>
    <InvestissementLMNP />
  </TopSection>
)

export default LMNPTopSection
