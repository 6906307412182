import React from 'react'
import styled from 'styled-components'

import PictoFast from '../../../assets/fast.inline.svg'
import LaunchButton from '../../atoms/LaunchButton'
import HomeDetails from '../../molecules/HomeDetails'
import HomeContainer from '../../atoms/HomeContainer'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoFast,
  title: 'En termes de détails, cette inscription doit avoir lieu dans les 15 jours qui suivent l\'entrée du locataire dans les lieux&nbsp;: le formulaire à remplir est le Cerfa P0i.',
  description: 'Un numéro SIRET sera attribué au propriétaire <strong>qui obtient alors le statut LMNP.</strong> Il faudra reporter ce numéro sur la feuille de déclaration de revenus complémentaires au moment de la déclaration d\'impôts.'
}]

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const LMNPDemarches = () => (
  <HomeContainer max={1200}>
    <HomeSectionTitle tag='h3'>
      Les démarches pour faire de la location meublée non professionnelle
    </HomeSectionTitle>
    <HomeSectionSubtitle>
     Certaines démarches sont obligatoires pour pouvoir faire de la location LMNP en toute légalité. Les procédures sont néanmoins très simples, donc pas de panique.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En effet, il suffit de s'inscrire auprès du greffe du tribunal de commerce compétent pour le bien concerné par la location. Pour les personnes qui possèdent plusieurs biens, il faudra s'adresser au greffe du tribunal de commerce du bien qui génère le plus de revenus locatifs.
    </HomeSectionDescription>
    <PictoColContainer details={DETAILS_COL}/>
    <BottomText>
      <strong>Les experts Folhomee vous accompagnent</strong><br/> dans vos démarches pour obtenir le statut LMNP.
    </BottomText>
    <StyledButtonContainer>
      <LaunchButton
        link='/nous-contacter'
        title='Contactez-nous' />
    </StyledButtonContainer>
  </HomeContainer>
)

export default LMNPDemarches
