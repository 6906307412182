import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import PictoShop from '../../../assets/shop.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import PictoTaxIncome from '../../../assets/tax-income.inline.svg'
import PictoPriceHouse from '../../../assets/price-house.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPriceHouse,
  title: 'Le revenu locatif',
  description: 'Il ne faut pas <strong>percevoir plus de 23 000€</strong> de revenus locatifs chaque année.'
}, {
  icon: PictoShop,
  title: 'Registre du Commerce et des Sociétés',
  description: 'Le propriétaire ne doit pas être inscrit au <strong>Registre du Commerce et des Sociétés (RCS)</strong> pour son activité, sinon il est considéré comme un loueur professionnel.'
}, {
  icon: PictoTaxIncome,
  title: 'Foyer fiscal',
  description: 'Les loyers des biens immobiliers ne doivent pas représenter <strong>plus de la moitié des revenus du foyer fiscal.</strong>'
}]

const GreyContainer = styled.div`
  background-color: ${({ theme }) => get(theme, 'paleGrey')}; 
  padding-top: 72px;
  padding-bottom: 72px;
`

const LMNPCriteres = () => (
  <GreyContainer>
    <HomeContainer max={1080}>
      <HomeSectionTitle tag='h3'>
        Les critères à respecter pour obtenir le statut LMNP
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Il existe des critères de revenus locatifs et de revenus fiscaux pour prétendre au statut LMNP. Tous les propriétaires ne peuvent effectivement pas faire de la location LMNP et profiter de ses avantages.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Voici les critères à respecter pour profiter de la location LMNP :
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        Par <strong>revenus du foyer fiscal,</strong> il est ici question de vos salaires et traitement, BNC (bénéfices non commerciaux), BIC (bénéfices industriels et commerciaux), rentes...
      </HomeSectionDescription>
      <HomeSectionDescription>
        L&apos;article 155 IV du Code Général des Impôts régit le statut LMNP en termes de déclarations fiscales. L'investisseur est donc tenu de déclarer toutes les sommes perçues au titre des investissements immobiliers dans la section BIC.
      </HomeSectionDescription>
    </HomeContainer>
  </GreyContainer>
)

export default LMNPCriteres
