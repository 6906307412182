import React from 'react'

import StyledList from '../../atoms/StyledList'
import PictoMoney from '../../../assets/money.inline.svg'
import PictoContract from '../../../assets/contract.inline.svg'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoPercentageHouse from '../../../assets/percentage-house.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoMoney,
  title: 'Intérêts d\'emprunt'
}, {
  icon: PictoContract,
  title: 'Frais'
}, {
  icon: PictoPercentageHouse,
  title: 'Taxes'
}]

const LMNPDeduction = () => (
  <HomeContainer max={1200}>
    <HomeSectionTitle tag='p'>
      Statut LMNP et déductions fiscales
    </HomeSectionTitle>
    <HomeSectionSubtitle>
       Il est possible de déduire toutes les charges attenantes à l'achat du bien immobilier avec le statut LMNP. Il n'y a pas de liste prédéfinie pour les charges déductibles.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Voici, globalement, les charges déductibles avec le statut LMNP :
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
    <HomeSectionDescription>
     Aussi, tous les amortissements du bien font partie des charges déductibles : il faut donc séparer le mobilier et les équipements du prix d'achat du bien. Pour le mobilier et l'équipement, un amortissement sur une durée de 5 à 8 ans est possible. En revanche, l'amortissement sur le prix d'achat du bien peut se faire sur une durée de 20 à 40 ans.
    </HomeSectionDescription>
    <br/>
    <HomeSectionDescription>
      Toutes les charges peuvent être déduites avec le statut de location meublée non professionnelle, sous réserve qu'elles :
    </HomeSectionDescription>
    <StyledList>
      <li>
        soient en <strong>lien direct</strong> avec la gestion du bien ;
      </li>
      <li>
       puissent être <strong>justifiées</strong> par une facture ;
      </li>
      <li>
        entrent dans l'exercice de <strong>l'année en cours.</strong>
      </li>
    </StyledList>
    <HomeSectionDescription>
      En somme, de nombreuses charges peuvent être déduites grâce au statut LMNP.
    </HomeSectionDescription>
  </HomeContainer>
)

export default LMNPDeduction