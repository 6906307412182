import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Residencies from '../../molecules/Residencies'
import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const RESIDENCIES = [{
  title: 'LMNP - Location Meublée Non Professionnelle',
  image: 'furniture',
  html: 'Location d\'un bien totalement meublé et équipé. Permet de louer son bien entre 20% et 40% plus cher que le prix du marché.'
}, {
  title: 'Location nue',
  image: 'nude',
  html: 'Location d\'un bien immobilier non meublé.'
}, {
  title: 'Location Pinel',
  image: 'pinel',
  html: '(<span>investissement locatif Pinel</span>)<br /> Investissement immobilier qui permet de bénéficier de déductions fiscales. Elle est soumise à différentes conditions de ressources, mais aussi concernant le bien.'
}, {
  title: 'Location Censi Bouvard',
  image: 'censi',
  html: 'Location immobilière grâce à laquelle il est possible de profiter de défiscalisation.'
}]

const DetailsContainer = styled.div`
  display: flex;
  margin: 32px auto;
  justify-content: center;
  gap: 16px;
  width: 80%;
  max-width: ${({ maxWidth }) => maxWidth};

  ${media.lessThan('md')`
    align-items: center;
    flex-direction: column;
  `}

  ${media.greaterThan('md')`
    flex-wrap: wrap;
    margin: 40px auto;
    width: 85%;
  `}

  ${media.greaterThan('lg')`
    width: 95%;
  `}
`

const SCREEN_SIZE = {
  xs: {
    width: '280px',
    height: '100%'
  },
  sm: {
    width: '400px',
    height: 'auto'
  },
  md: {
    width: '600px',
    height: '400px'
  }
}

const LMNPTypesInvestissement = () => (
  <>
    <HomeContainer max={1300}>
      <HomeSectionTitle tag='h3'>
        Les différents types d'investissement
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Avant de vous lancer dans un investissement locatif LMNP, il est important d'avoir conscience de tous les types d'investissement immobilier qui existent
      </HomeSectionSubtitle>
      <HomeSectionDescription>
       Ainsi, voici un rapide récapitulatif de toutes les façons possibles d'investir dans l'immobilier lorsqu'on a pour but de louer son bien&nbsp;:
      </HomeSectionDescription>
    </HomeContainer>
    <DetailsContainer maxWidth='1080px'>
      <Residencies
        size={SCREEN_SIZE}
        small={true}
        residencies={RESIDENCIES} />
    </DetailsContainer>
  </>
)

Residencies.defaultProps = {
  maxWidth: 'fit-content'
}

export default LMNPTypesInvestissement
