import React from 'react'

import HomeContainer from '../../atoms/HomeContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const LMNPStatut = () => (
  <HomeContainer max={1080}>
    <HomeSectionTitle tag='h3'>
      Qu'est-ce que le statut LMNP&nbsp;?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Il n'y a pas vraiment de texte de loi pour la location LMNP, mais il est possible de proposer une définition détaillée de ce procédé locatif.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Les lois qui encadrent la location meublée non professionnelle décrivent notamment ce statut de location, avec des critères qui lui sont propres.
    </HomeSectionDescription>
    <HomeSectionDescription>
      En effet, <strong>le statut LMNP a été crée en 1949</strong> et a pour but d'inciter les propriétaires à louer leur bien immobilier meublé. Afin d'attirer les propriétaires, des déductions fiscales sont liées à la location meublée non professionnelle. Les loueurs peuvent effectivement bénéficier d'un amortissement en proposant leur logement sous le régime de la LMNP.
    </HomeSectionDescription>
  </HomeContainer>
)

export default LMNPStatut
