import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from '../../../utils/media'

const OldContainer = styled.div`
  width: 100%;
  padding: 0 24px 152px;
  padding-top: 150px;
  z-index: 999;
  ${media.lessThan('sm')`
    padding-top: 80px;
  `}
  ${media.lessThan('md')`
    min-width: 200px;
  `}
  ${media.greaterThan('md')`
    padding: 0 48px 152px;
    padding-top: 150px;
  `}
  ${media.greaterThan('lg')`
    max-width: 600px;
    margin: 0 0 0 8%;
    padding-bottom: 176px;
  `}
`

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  ${media.lessThan('sm')`
    padding-right: 20px;
    padding-left: 20px;
  `}
`

const LMNPContainer = ({ oldType, children, ...props }) => {
  if (oldType) {
    return (
      <OldContainer {...props}>
        {children}
      </OldContainer>
    )
  }

  return (
    <Container {...props}>
      {children}
    </Container>
  )
}

LMNPContainer.propTypes = {
  oldType: PropTypes.bool,
  children: PropTypes.any
}

LMNPContainer.defaultProps = {
  oldType: true
}

export default LMNPContainer