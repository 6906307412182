import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import LMNPAutres from '../components/organisms/LMNPAutres'
import LMNPStatut from '../components/organisms/LMNPStatut'
import SiteSection from '../components/atoms/SiteSection'
import LMNPDetails from '../components/organisms/LMNPDetails'
import LMNPCriteres from '../components/organisms/LMNPCriteres'
import LMNPAvantages from '../components/organisms/LMNPAvantages'
import LMNPDeduction from '../components/organisms/LMNPDeduction'
import LMNPDemarches from '../components/organisms/LMNPDemarches'
import LMNPTopSection from '../components/organisms/LMNPTopSection'
import LMNPTypesInvestissement from '../components/organisms/LMNPTypesInvestissement'

const TITLE = 'Statut LMNP : comment l\'obtenir et quels avantages ?'
const DESCRIPTION = 'La location LMNP présente divers avantages. Quels sont-ils et comment en profiter ?'

const LastSection = styled(SiteSection)`
  margin-bottom: 0;
`

const InvestissementLmnp = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <LMNPTopSection />
    <SiteSection id='investissement-lmnp-types' $first={true}>
      <LMNPTypesInvestissement />
    </SiteSection>
    <SiteSection id='investissement-lmnp-détails'>
      <LMNPDetails />
    </SiteSection>
    <SiteSection id='investissement-statut'>
      <LMNPStatut />
    </SiteSection>
    <SiteSection id='investissement-criteres'>
      <LMNPCriteres />
    </SiteSection>
    <SiteSection id='investissement-demarches'>
      <LMNPDemarches />
    </SiteSection>
    <SiteSection id='investissement-avantages'>
      <LMNPAvantages />
    </SiteSection>
    <SiteSection id='investissement-deduction'>
      <LMNPDeduction />
    </SiteSection>
    <LastSection id='investissement-autres' $last={true}>
      <LMNPAutres />
    </LastSection>
    <Footer noImage={true} noForm={true} />
  </>
)

InvestissementLmnp.propTypes = {
  location: PropTypes.object.isRequired
}

export default InvestissementLmnp
