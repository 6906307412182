import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/LMNPContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
  `}

  ${media.greaterThan('xl')`
    max-width: 520px;
    margin-right: 55%;
  `}
`

const InvestissementLMNP = () => (
  <>
    <StyledContainer>
      <Header max={100}>
        Investissement locatif LMNP
      </Header>
      <Subtitle>
        L’investissement locatif est un dispositif qui présente divers avantages. Il existe différentes façons d’investir dans un bien à vocation locative, dont la location meublée non professionnelle (LMNP).
      </Subtitle>
      <Description>
        Conditions de ressources, démarches administratives, avantages... Tout ce que vous devez savoir sur l’investissement locatif LMNP se trouve dans cette page.
      </Description>
    </StyledContainer>
  </>
)

export default InvestissementLMNP
