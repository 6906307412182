import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(ImageSection)`
  max-height: fit-content;
`

const LMNPDetails = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "lmnp-details.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <StyledImageSection
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        La location LMNP en détails
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La location LMNP est un statut locatif particulier qui suppose de répondre à certaines conditions.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En effet, les personnes qui souhaitent louer leur bien sous le statut de la location meublée non professionnelle doivent s'assurer de respecter différents critères. Sans cela, il faudra relever de la location meublée professionnelle, aussi appelée LMP.
      </HomeSectionDescription>
    </StyledImageSection>
  )
}

export default LMNPDetails
